import { useFetch } from "~vue/api";
import { ref } from "vue";

/**
 * composable for uploading files from chat
 * composes useFetch to make the API call, can return everything useFetch API returns
 * https://vueuse.org/core/useFetch/
 */
export const useFileUpload = () => {
    const uploadPayload = ref(null);
    const { execute, ...fetchObj } = useFetch("/api/chat/document/upload", {
        immediate: false,
    })
        .post(uploadPayload)
        .json();

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        uploadPayload.value = formData;
        return execute();
    };

    return {
        ...fetchObj,
        uploadFile,
    };
};
